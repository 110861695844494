<template>
  <div>

    <div class="error-page-block d-flex flex-column align-items-center">
      <Error503Img/>

      <p>
        Seems something went wrong. Let’s go to dashboard so that start the way from the very beginning
      </p>

      <router-link :to="$store.getters.GET_PATHS.dashboard">
        <MainButton
                class="wfc"
                :value="'Go to Dashboard'"
        />
      </router-link>

    </div>
  </div>
</template>

<script>
  import Error503Img from '../../../../public/img/common/503-img.svg?inline'
  import MainButton from "../../UI/buttons/MainButton/MainButton";

  export default {
    name: "Error503Module",

    components: {
      MainButton,
      Error503Img,
    },

    data() {
      return {

      }
    },

    created() {

    },

  }
</script>

<style scoped>

</style>
